function Card(props) {
    return ( 
        <div className="card">
            <img src={props.img} alt={props.title + " icon"} className="card-img" />
            <div className="card-content">
                <div className="title">{props.title}</div>
                <div className="description">{props.description}</div>
            </div>
        </div>
     );
}

export default Card;