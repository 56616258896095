import "../css/Hamburger.css";
function HamburgerButton(props) {
    function handleClick() {
        if (!props.ghost) {
            props.menuRef.current.classList.toggle("show");
        }
    }

    return (
        <button onClick={handleClick} className={`hamburger-button ${props.ghost ? "ghost" : ""} ${props.dark ? "dark" : ""}`} aria-label="Open menu to see site map">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
        </button>
    );
}

export default HamburgerButton;
