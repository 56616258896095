import "../css/SectionHeader.css";

function SectionHeader({
    subtitle,
    title,
    description,
    descriptionMaxWidth,
    longSeparator = false,
    dark = false,
    compact = false,
    customDescriptionStyle = {},
    img = null
}) {
    return (
        <div className={"section-header " + (dark ? "dark " : "") + (compact ? "compact " : "")}>
            {!compact && <div className="subtitle">{subtitle}</div>}
            <div className="title">{title}</div>
            <div className={`separator ${longSeparator ? "long" : ""}`}></div>
            {img && <img src={img} alt="Powered by The Real Human Project"/>}
            <div
                className="description"
                style={{
                    ...customDescriptionStyle,
                    maxWidth: descriptionMaxWidth,
                }}
            >
                {description}
            </div>
        </div>
    );
}

export default SectionHeader;
