import "../css/EmailCard.css";

function EmailCard({ img, name, description, href }) {
    return (
        <a href={href} className="email-card" aria-label={name + " email client link"}>
            <div className="img-container">
                <img src={img} alt={name + " icon"} />
            </div>
            <div className="title">{name}</div>
            <div className="description">{description}</div>
        </a>
    );
}

export default EmailCard;
