import { orgData } from "../orgUtils";
import ImageSection from "./ImageSection";

function OrgBanner({ id, orgName, label, children, fallback }) {
    const orgImgURL = (org, label) => {
        return orgData[org]?.customBanners?.includes(label)
            ? `https://assets.cdn.geta-head.com/keelreels/${org}/img/${org}_${label}.webp`
            : fallback;
    };

    return (
        <ImageSection id={id} bgImgUrl={orgImgURL(orgName, label)}>
            {children}
        </ImageSection>
    );
}

export default OrgBanner;
