import "../css/Carousel.css";

import Carousel from "react-multi-carousel";

function CarouselWrapper(props) {
    return (
        <Carousel
            swipeable={true}
            showDots={true}
            responsive={{
                desktop: {
                    breakpoint: {max: 30000, min: 1024 },
                    items: Math.min(3, props.children.length),
                    slidesToSlide: 3, // optional, default to 1.
                },
                tablet: {
                    breakpoint: { max: 1024, min: 850 },
                    items: Math.min(2, props.children.length),
                    slidesToSlide: 2, // optional, default to 1.
                },
                mobile: {
                    breakpoint: { max: 850, min: 0 },
                    items: 1,
                    slidesToSlide: 1, // optional, default to 1.
                },
            }}
            containerClass="carousel-container"
        >
            {props.children}
        </Carousel>
    );
}

export default CarouselWrapper;
