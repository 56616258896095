import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import Home from "./pages/Home";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProgramChoice from "./pages/ProgramChoice";
import BuildingTrust from "./pages/BuildingTrust";
import PeerSupportProgram from "./pages/PeerSupportProgram";
import EmailSuggestions from "./pages/EmailSuggestions";
import CrisisSupport from "./pages/CrisisSupport";
import ReadinessChecks from "./pages/ReadinessChecks";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                {/* unbranded routes */}
                <Route path="*" element={<Home />} />
                <Route
                    path="peer-support-program-choice"
                    element={<ProgramChoice />}
                />
                <Route path="building-trust" element={<BuildingTrust />} />
                <Route
                    path="peer-support-program"
                    element={<PeerSupportProgram />}
                />
                <Route
                    path="email-suggestions"
                    element={<EmailSuggestions />}
                />
                <Route path="crisis-support" element={<CrisisSupport />} />
                <Route path="readiness-checks" element={<ReadinessChecks />} />

                {/* org routes  */}
                <Route path=":org/*" element={<Home />} />
                <Route
                    path=":org/peer-support-program-choice"
                    element={<ProgramChoice />}
                />
                <Route path=":org/building-trust" element={<BuildingTrust />} />
                <Route
                    path=":org/peer-support-program"
                    element={<PeerSupportProgram />}
                />
                <Route
                    path=":org/email-suggestions"
                    element={<EmailSuggestions />}
                />
                <Route path=":org/crisis-support" element={<CrisisSupport />} />
                <Route path=":org/readiness-checks" element={<ReadinessChecks />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
