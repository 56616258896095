import "../css/Home.css";
import "../css/Fonts.css";
import { useEffect, useRef } from "react";
import ImageSection from "../components/ImageSection";
import CTAButton from "../components/CTAButton";
import SectionHeader from "../components/SectionHeader";
import CardGrid from "../components/CardGrid";
import Card from "../components/Card";
import Reel from "../components/Reel";
import HamburgerMenu from "../components/HamburgerMenu";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CarouselWrapper from "../components/Carousel";
import "react-multi-carousel/lib/styles.css";
import { orgPageSetup, orgData } from "../orgUtils";
import { useParams } from "react-router-dom";
import OrgLogo from "../components/OrgLogo";
import OrgBanner from "../components/OrgBanner";
function Home() {
    const menuRef = useRef(null);
    const homePageRef = useRef(null);
    const params = useParams();

    useEffect(() => {
        orgPageSetup(homePageRef, params.org);
    }, [params]);

    const reelsOrg =
        orgData[params.org]?.reels.length > 0 ? params.org : "keel";
    const reels = orgData[reelsOrg].reels;

    return (
        <div id="home-page" ref={homePageRef} className="default-org-page">
            <HamburgerMenu menuRef={menuRef} />
            <OrgBanner
                id={"hero-section"}
                orgName={params.org}
                label={"hero"}
                fallback={"/hero-bg.webp"}
            >
                <Header menuRef={menuRef} />
                <div id="hero-body">
                    <div id="hero-body-content">
                        Let’s be the first to respond to those who{" "}
                        <span>respond first</span>.
                    </div>
                    <p>
                        At Keel Mind, we are committed to revolutionizing mental
                        health support. Let's build a healthier, more
                        compassionate world.
                    </p>
                    <OrgLogo
                        org={
                            params.org &&
                            Object.keys(orgData).includes(params.org)
                                ? params.org
                                : "keel"
                        }
                        useLogoSize={true}
                    />

                    <CTAButton />
                </div>
                <div className="bottom-of-section">
                    <img
                        onClick={() => {
                            document.querySelector("#about").scrollIntoView();
                        }}
                        src="/down-arrows.png"
                        alt="Down arrows"
                        className="down-arrows"
                        aria-label="Scroll down to content"
                    />
                    <div className="bottom-vertical-line"></div>
                </div>
            </OrgBanner>
            <section id="about">
                <SectionHeader
                    title={"Why Keel Mind?"}
                    subtitle={"A Little About Us"}
                    descriptionMaxWidth={666}
                    description={
                        "At Keel Mind we are committed to revolutionizing mental health support. Our platform combines cutting-edge technology with compassionate human connections to address the challenges faced by both peer supporters and police departments."
                    }
                />
                <CardGrid>
                    <Card
                        title={"Empowering Peer Supporters"}
                        description={
                            "We equip peer supporters with advanced tools to optimize their impact. From AI-driven chatbots to personalized training modules, we ensure they have the resources needed to make a difference."
                        }
                        img={"/empowering.png"}
                    />
                    <Card
                        title={"Scaling Peer Support Networks"}
                        description={
                            "By expanding the network of peer supporters, we reduce the burden on professional providers. Our platform connects individuals seeking help with trained peers, fostering a sense of community and understanding."
                        }
                        img={"/scaling.png"}
                    />
                    <Card
                        title={"Building Trust and Assurity"}
                        description={
                            "Police departments face a mental health epidemic among officers. We collaborate closely with law enforcement agencies, providing confidential support, mental health training, and resources to enhance officer well-being."
                        }
                        img={"/building.png"}
                    />
                    <Card
                        title={"Creating a Healthier Future"}
                        description={
                            "Our mission extends beyond technology. We envision a world where mental health is prioritized, stigma is eradicated, and everyone has access to compassionate care."
                        }
                        img={"/creating.png"}
                    />
                </CardGrid>
            </section>
            <OrgBanner
                fallback={"/confidential-support-bg.png"}
                id={"building-trust-section"}
                orgName={params.org}
                label={"buildingTrust"}
            >
                <SectionHeader
                    dark={true}
                    longSeparator={true}
                    title={"Building Trust & Assurity"}
                    subtitle={"Confidential Support"}
                    description={
                        "Police departments face a mental health epidemic among officers. We collaborate closely with law enforcement agencies, providing confidential support, mental health training, and resources to enhance officer well-being."
                    }
                    descriptionMaxWidth={627}
                    customDescriptionStyle={{
                        lineHeight: "26px",
                        marginTop: "20px",
                    }}
                />
                <div
                    style={{
                        height: 54,
                    }}
                ></div>
                <CTAButton
                    text="See How We're Building Trust"
                    alt={true}
                    href="/building-trust"
                />
            </OrgBanner>
            <section id="keel-reels-section">
                <SectionHeader
                    title={"Introducing Keel Reels"}
                    subtitle={"The Real Human Project"}
                    description={
                        "In just minutes, immerse yourself in an interview with a humanistic AI-driven chatbot, delving into your lived experiences and passion for the realm of peer support. "
                    }
                    img={"/powered_by_real_human.png"}
                />
                <div className="reels-container-heading">
                    Witness firsthand how sharing your story fosters deeper
                    connections with your others.
                </div>
                <CarouselWrapper>
                    {reels.map((obj) => (
                        <Reel key={obj.name} reelsOrg={reelsOrg} {...obj} />
                    ))}
                </CarouselWrapper>
            </section>
            <section id="wellness-hub-section">
                <div className="grid-container">
                    <div
                        id="wellness-hub-graphic-container"
                        className="graphic-container"
                    >
                        <img
                            src="/wellness-hub-graphic.png"
                            alt="Wellness Hub graphic"
                        />
                    </div>

                    <div className="grid-content">
                        <SectionHeader
                            compact={true}
                            title={"The\nWellness Hub"}
                            description={
                                "At Keel Mind, we believe access to information is part of your wellness journey, we provide access for our users to consume custom curated and exclusive content in all forms of media from blog posts, podcasts to video series from world recognized authors."
                            }
                        />
                        <div className="wellness-hub-links">
                            <strong>
                                Content for Training Peer Support Specialists
                            </strong>
                            <span> | </span>
                            <strong>Content for Mental Wellness</strong>
                        </div>
                        {params.org !== "ledrexecutive" && <CTAButton />}
                    </div>
                </div>
            </section>
            <section>
                <div className="grid-container">
                    <div className="grid-content">
                        <SectionHeader
                            compact={true}
                            title={"Keel Mind\nReadiness Check"}
                            description={
                                "This feature allows you to participate in AI-driven mental fitness activities aimed at enhancing performance and gaining valuable, scientifically supported insights into areas such as burnout, stress, and mental strain."
                            }
                        />
                        <div className="games-button-container">
                            <CTAButton
                                href="/readiness-checks"
                                text={
                                    params.org === "ledrexecutive"
                                        ? "Start a Readiness Check"
                                        : undefined
                                }
                            />
                        </div>
                    </div>

                    <div
                        id="keel-games-graphic-container"
                        className="graphic-container"
                    >
                        <img
                            src="/readiness_check.png"
                            alt="Thymia readiness check graphic"
                        />
                    </div>
                </div>
            </section>
            <ImageSection id="bottom-hero" bgImgUrl="/bottom-hero.png">
                <div className="heading">
                    Join us in this transformative journey toward mental
                    wellness. <span>Together</span>, we can make a lasting
                    impact.
                </div>
                <div className="description">
                    We're dedicated to fostering resilience, empathy, and hope
                    in the face of mental health challenges for our first
                    responders.
                </div>
                {params.org === "ledrexecutive" ? (
                    <CTAButton
                        alt={true}
                        text="Contact Us For More Info"
                        href={`mailto:JuliaH@Keel.Digital?subject=Keel%20Mind%20Peer%20Support%20Contact${
                            orgData[params.org]
                                ? `%20[${orgData[params.org].name}]`
                                : ``
                        }`}
                    />
                ) : (
                    <CTAButton alt={true} />
                )}
            </ImageSection>
            <Footer>
                <div className="footer-images">
                    <OrgLogo
                        org={
                            params.org &&
                            Object.keys(orgData).includes(params.org)
                                ? params.org
                                : "keel"
                        }
                        useLogoSize={true}
                    />
                </div>
            </Footer>
        </div>
    );
}

export default Home;
