import "../css/CrisisSupport.css";
import { useEffect, useRef } from "react";
import HamburgerMenu from "../components/HamburgerMenu";
import Header from "../components/Header";
import Footer from "../components/Footer";

function CrisisSupport() {
    const menuRef = useRef(null);

    useEffect(() => {
        document.title = "Crisis Support"
    })
    return (
        <div id="Crisis-Support-page" className="basic card-page">
            <HamburgerMenu menuRef={menuRef} />
            <div id="emergency-banner">
                If your life is in danger or someone you know, don't hesitate to
                DIAL EMERGENCY NOW
                <a href="tel:911" aria-label="Call 911 to report a crisis">Please Call 911</a>
            </div>
            <Header menuRef={menuRef} dark={true} />
            <h1 className="heading">Crisis Support</h1>
            <div className="description">
                Life can be really hard sometimes. If you are experiencing a
                crisis and/or experiencing suicidal thoughts, you are not alone.{" "}
                <strong>Help is just a text or phone call away.</strong>
            </div>

            <div className="card-page-card">
                <div className="card-info">
                    <img src="/boots.png" alt="Boots On The Ground logo" />
                    <h2 className="heading">Boots On The Ground</h2>
                    <div className="description">
                        Please visit{" "}
                        <a href="https://www.bootsontheground.ca" aria-label="Boots On The Ground link">
                            www.bootsontheground.ca
                        </a>{" "}
                        or call us at{" "}
                        <strong>CALL 24/7: 1-833-677-BOOT (2668)</strong>
                    </div>
                </div>
                <div className="separator"></div>
                <div className="card-info">
                    <img src="/text.png" alt="Text support" />
                    <h2 className="heading">24/7 Crisis Text Line</h2>
                    <div className="description">
                        Text TALK to 686868 to reach an English speaking Crisis
                        Responder, or TEXTO to 686868 to reach a French-speaking
                        Crisis Responder on any text/SMS enabled cell phone.
                    </div>
                </div>
                <div className="separator"></div>
                <div className="card-info">
                    <img src="/suicide.png" alt="9-8-8 suicide hotline" />
                    <h2 className="heading">Talk Suicide</h2>
                    <div className="description">
                        Connect to a crisis responder for help in English or
                        French without judgement. Call 1-833-456-4566 toll-free,
                        any time - or text 45645 from 4 P.M to midnight ET.
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default CrisisSupport;
