import { useParams } from "react-router-dom";
import HeaderLogo from "./HeaderLogo";
import OrgLink from "./OrgLink";

const links = {
    "Get Started": "/peer-support-program-choice",
    "Building Trust": "/building-trust",
    "Peer Support Program": "/peer-support-program",
    "Crisis Support": "/crisis-support",
    "Anonymous Options": "/email-suggestions",
    "Readiness Checks": "/readiness-checks",
};

function HamburgerMenu(props) {
    const params = useParams();
    function handleCloseClick() {
        props.menuRef.current.classList.toggle("show");
    }

    return (
        <div className="hamburger-menu-wrapper" ref={props.menuRef}>
            <div className="cover" onClick={handleCloseClick}></div>
            <div className="menu-scroll-wrapper">
                <div className="menu">
                    <div className="menu-header">
                        <button
                            onClick={handleCloseClick}
                            className="close-button hidden"
                            aria-label="Close menu"
                        >
                            <div className="line"></div>
                            <div className="line"></div>
                        </button>
                        <HeaderLogo />
                        <button
                            onClick={handleCloseClick}
                            className="close-button"
                        >
                            <div className="line"></div>
                            <div className="line"></div>
                        </button>
                    </div>
                    {Object.entries(links)
                        .filter(([name, link]) => {
                            if (
                                params.org === "ledrexecutive" &&
                                name === "Peer Support Program"
                            ) {
                                return false;
                            }
                            return true;
                        })
                        .map(([name, link]) => {
                            return (
                                <OrgLink key={name} to={link}>
                                    {name}
                                </OrgLink>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}

export default HamburgerMenu;
