import '../css/Header.css'
import HamburgerButton from "./HamburgerButton";
import HeaderLogo from './HeaderLogo';

function Header({menuRef, dark=false}) {

    return <div className="header">
        <HamburgerButton dark={dark} menuRef={menuRef} />
        <HeaderLogo dark={dark} />
        <HamburgerButton ghost={true} />
    </div>;
}

export default Header;

