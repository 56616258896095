import "../css/PeerSupportProgram.css";
import { useEffect, useRef, useState } from "react";
import HamburgerMenu from "../components/HamburgerMenu";
import HamburgerButton from "../components/HamburgerButton";
import Footer from "../components/Footer";
import HeaderLogo from "../components/HeaderLogo";
import OrgLink from "../components/OrgLink";

function PeerSupportProgram() {
    const menuRef = useRef(null);
    const [formState, setFormState] = useState("input");

    const handleFormSubmit = (event) => {
        event.preventDefault();
        const email = encodeURIComponent(event.target.email.value);
        fetch(
            `https://docs.google.com/forms/d/e/1FAIpQLSdc0JRo-v5dbm2nyO3lP5PwfbGO9bNnG5Zs4EHkoo-nsynDQQ/formResponse?entry.802496195=${email}`,
            {
                method: "GET",
                mode: "no-cors",
            }
        )
            .then(() => {
                setFormState("success");
            })
            .catch(() => {
                setFormState("error");
            });
    };

    useEffect(() => {
        document.title = "Peer Support Program";
    });
    return (
        <div id="Peer-Support-Program-page" className="basic">
            <HamburgerMenu menuRef={menuRef} />
            <div
                className="hero"
                style={{
                    backgroundImage: `url(/support-hero.jpg)`,
                }}
            >
                <HamburgerButton menuRef={menuRef} />
            </div>
            <div className="content">
                <HeaderLogo dark={true} />
                <h1 className="heading">Need someone to talk to?</h1>
                <div className="description">
                    <strong>We'll be ready for you soon!</strong> Please drop
                    your email address in the field below, you will receive
                    instructions on how to proceed when the program is launched!
                </div>
                <div className="form-container">
                    <form onSubmit={handleFormSubmit} aria-label="Waitlist sign up form">
                        {{
                            error: <div className="form-error" aria-label="Error while submitting the form">Oops! Something went wrong while submitting the form.</div>,
                            success: <div className="form-success" aria-label="Form successfully submitted">Thank you! Your submission has been received!</div>,
                        }[formState] || (
                            <>
                                <input
                                    type="email"
                                    required
                                    name="email"
                                    placeholder="Enter your email"
                                    maxLength={254}
                                    aria-label="Email address"
                                />
                                <input type="submit" value="Keep me notified" aria-label="Submit form" />
                            </>
                        )}
                    </form>
                    <div className="other-email">
                        <span>
                            *Don't want to use your personal email? There's
                            options{" "}
                            <OrgLink to="/email-suggestions">here</OrgLink>.
                        </span>
                    </div>
                    <div className="separator-container">
                        <div className="separator"></div>
                        <span>or</span>
                        <div className="separator"></div>
                    </div>
                    <OrgLink to="/crisis-support" className="assistance-button">
                        I Need Assistance Now!
                    </OrgLink>
                </div>
                <Footer
                    style={{
                        padding: 0,
                    }}
                    className={"stack"}
                />
            </div>
        </div>
    );
}

export default PeerSupportProgram;
