import OrgLink from "./OrgLink";

function HeaderLogo({ dark }) {
    return (
        <OrgLink to={"/"} className="header-logo-a">
            <img
                className="header-logo"
                draggable="false"
                src={dark ? "/keel-mind-logo-dark.png" : "/keel-mind-logo.png"}
                alt="Keel Mind logo"
            />
        </OrgLink>
    );
}

export default HeaderLogo;
